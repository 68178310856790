import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./tailwind.css";

// Set the refresh interval to 12 hours
const refreshInterval = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

// Get the current time and the last refresh time from localStorage
const currentTime = new Date().getTime();
const lastRefreshTime = localStorage.getItem("lastRefreshTime");

if (lastRefreshTime) {
  const timeSinceLastRefresh = currentTime - lastRefreshTime;

  if (timeSinceLastRefresh >= refreshInterval) {
    // If 12 hours have already passed, refresh the page
    window.location.reload();
  } else {
    // Set a timeout to refresh the page when 12 hours have passed
    setTimeout(() => {
      window.location.reload();
    }, refreshInterval - timeSinceLastRefresh);
  }
} else {
  // If there is no last refresh time, set it now
  localStorage.setItem("lastRefreshTime", currentTime);
  // Set a timeout to refresh the page in 12 hours
  setTimeout(() => {
    window.location.reload();
  }, refreshInterval);
}

// Update the last refresh time in localStorage on page load
localStorage.setItem("lastRefreshTime", currentTime);

document.fonts.ready.then(() => {
  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
