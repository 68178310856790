import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { db } from "./firebase";
import {
  collection,
  doc,
  getDoc,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import VinCollectPDF from "./components/VinCollectPDF";
import { format } from "date-fns";

const createTaskWithUser = (
  docSnapshot,
  taskData,
  userData,
  shift,
  tasksInShift
) => {
  const task = {
    id: docSnapshot.id,
    ...taskData,
    user: userData
      ? {
          id: taskData.userId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          rate: userData.rate,
          salary: userData.salary,
        }
      : null,
    shift,
  };

  return {
    ...task,
    ...calculateMetrics(task, shift, tasksInShift),
  };
};

const calculateMetrics = (task, shift, tasksInShift) => {
  if (!shift?.startTimestamp) {
    return {
      workedHours: 0,
      revenue: task.price || 0,
      payroll: 0,
      grossProfit: 0,
      grossProfitPercentage: 0,
      tasksPerHour: 0,
      revenuePerHour: 0,
      profitPerHour: 0,
    };
  }

  const shiftStart = shift.startTimestamp.toDate();
  const shiftEnd = shift.endTimestamp
    ? shift.endTimestamp.toDate()
    : new Date();

  // Calculate total milliseconds only for "Vin Collect" periods
  let totalMilliseconds = 0;

  if (shift.roleChanges && shift.roleChanges.length > 0) {
    const roleChanges = [...shift.roleChanges].sort(
      (a, b) => a.timestamp.seconds - b.timestamp.seconds
    );

    let lastVinCollectStart = null;
    let currentRole = roleChanges[0].role;

    // Add shift start if first role is "Vin Collect"
    if (currentRole === "Vin Collect") {
      lastVinCollectStart = shiftStart;
    }

    // Process role changes
    for (let i = 1; i < roleChanges.length; i++) {
      const changeTime = roleChanges[i].timestamp.toDate();

      if (currentRole === "Vin Collect" && lastVinCollectStart) {
        totalMilliseconds += changeTime - lastVinCollectStart;
        lastVinCollectStart = null;
      } else if (roleChanges[i].role === "Vin Collect") {
        lastVinCollectStart = changeTime;
      }

      currentRole = roleChanges[i].role;
    }

    // Handle final period if ended in "Vin Collect"
    if (currentRole === "Vin Collect" && lastVinCollectStart) {
      totalMilliseconds += shiftEnd - lastVinCollectStart;
    }
  } else {
    // If no role changes, assume entire shift was Vin Collect
    totalMilliseconds = shiftEnd - shiftStart;
  }

  // Subtract breaks if any
  if (shift.breakTimestamps && shift.breakTimestamps.length > 1) {
    for (let i = 0; i < shift.breakTimestamps.length; i += 2) {
      const breakStart = new Date(shift.breakTimestamps[i]);
      const breakEnd = shift.breakTimestamps[i + 1]
        ? new Date(shift.breakTimestamps[i + 1])
        : new Date();

      if (breakStart >= shiftStart && breakEnd <= shiftEnd) {
        totalMilliseconds -= breakEnd - breakStart;
      }
    }
  }

  // Convert to precise hours (e.g., 9.0333... for 9 hours and 2 minutes)
  const totalHours = totalMilliseconds / (1000 * 60 * 60);
  const workedHours = totalHours / tasksInShift;
  const revenue = task.price || 0;

  // Calculate payroll using precise hours
  let payroll = 0;
  const hourlyRate = parseFloat(shift.rate) || parseFloat(task.user?.rate) || 0;

  if (hourlyRate > 0) {
    payroll = workedHours * hourlyRate;
  } else if (task.user?.salary && !isNaN(parseFloat(task.user.salary))) {
    const hourlyWage = parseFloat(task.user.salary) / (260 * 8);
    payroll = workedHours * hourlyWage;
  }

  // Calculate metrics using precise hours
  const grossProfit = revenue - payroll;
  const grossProfitPercentage = revenue ? (grossProfit / revenue) * 100 : 0;
  const tasksPerHour = totalHours > 0 ? tasksInShift / totalHours : 0;
  const revenuePerHour = workedHours > 0 ? revenue / workedHours : 0;
  const profitPerHour = workedHours > 0 ? grossProfit / workedHours : 0;

  // Return raw values without formatting
  return {
    workedHours, // Return the raw hours value
    revenue,
    payroll,
    grossProfit,
    grossProfitPercentage,
    tasksPerHour,
    revenuePerHour,
    profitPerHour,
  };
};

const fetchTasks = async (startDate, endDate) => {
  const tasksCollection = collection(db, "tasks");
  const shiftsCollection = collection(db, "shifts");

  const startTimestamp = new Date(startDate);
  startTimestamp.setHours(0, 0, 0, 0);

  const endTimestamp = new Date(endDate);
  endTimestamp.setHours(23, 59, 59, 999);

  const tasksQuery = query(
    tasksCollection,
    where("startTimestamp", ">=", startTimestamp),
    where("startTimestamp", "<=", endTimestamp)
  );

  const shiftsQuery = query(
    shiftsCollection,
    where("startTimestamp", ">=", startTimestamp),
    where("startTimestamp", "<=", endTimestamp)
  );

  return new Promise((resolve, reject) => {
    let tasks = [];
    const shiftsMap = {};
    const vinCollectUsers = {};
    let unsubscribeTasks, unsubscribeShifts;

    // Subscribe to shifts changes first
    unsubscribeShifts = onSnapshot(
      shiftsQuery,
      (shiftsSnapshot) => {
        // Update shiftsMap and vinCollectUsers when shifts change
        shiftsSnapshot.docs.forEach((doc) => {
          const shiftData = doc.data();
          const shiftDate = shiftData.startTimestamp.toDate().toDateString();
          const shiftKey = `${shiftData.userId}_${shiftDate}`;
          shiftsMap[shiftKey] = { id: doc.id, ...shiftData };

          // Check for Vin Collect role
          if (shiftData.roleChanges) {
            const isVinCollect = shiftData.roleChanges.some(
              (change) => change.role === "Vin Collect"
            );
            if (isVinCollect) {
              const userId = shiftData.userId;
              vinCollectUsers[userId] = {
                id: userId,
                ...shiftData,
              };
            }
          }
        });

        // If we already have tasks, update them with new shift data
        if (tasks.length > 0) {
          updateTasksWithShifts();
        }
      },
      reject
    );

    // Subscribe to tasks changes
    unsubscribeTasks = onSnapshot(
      tasksQuery,
      async (tasksSnapshot) => {
        try {
          const shiftTaskCounts = {};

          // Update task counts
          tasksSnapshot.docs.forEach((doc) => {
            const taskData = doc.data();
            const taskDate = taskData.startTimestamp.toDate().toDateString();
            const shiftKey = `${taskData.userId}_${taskDate}`;
            shiftTaskCounts[shiftKey] = (shiftTaskCounts[shiftKey] || 0) + 1;
          });

          // Process tasks with user data
          tasks = await Promise.all(
            tasksSnapshot.docs.map(async (docSnapshot) => {
              const taskData = docSnapshot.data();
              const taskDate = taskData.startTimestamp.toDate().toDateString();
              const shiftKey = `${taskData.userId}_${taskDate}`;
              const shift = shiftsMap[shiftKey];
              const tasksInShift = shiftTaskCounts[shiftKey] || 1;

              try {
                const userDoc = await getDoc(doc(db, "users", taskData.userId));
                const userData = userDoc.data();

                if (!userData) {
                  console.warn(`No user data found for userId: ${taskData.userId}`);
                  return createTaskWithUser(docSnapshot, taskData, null, shift, tasksInShift);
                }

                return createTaskWithUser(docSnapshot, taskData, userData, shift, tasksInShift);
              } catch (error) {
                console.error(`Error fetching user data for task ${docSnapshot.id}:`, error);
                return createTaskWithUser(
                  docSnapshot,
                  taskData,
                  { firstName: "Error", lastName: "Loading" },
                  shift,
                  tasksInShift
                );
              }
            })
          );

          resolve({ tasks, vinCollectUsers }); // Return both tasks and vinCollectUsers
        } catch (error) {
          reject(error);
        }
      },
      reject
    );

    // Helper function to update tasks with current shift data
    const updateTasksWithShifts = () => {
      const updatedTasks = tasks.map((task) => {
        const taskDate = task.startTimestamp.toDate().toDateString();
        const shiftKey = `${task.userId}_${taskDate}`;
        const shift = shiftsMap[shiftKey];
        return {
          ...task,
          shift,
        };
      });
      resolve({ tasks: updatedTasks, vinCollectUsers }); // Return both tasks and vinCollectUsers
    };

    // Return cleanup function
    return () => {
      unsubscribeTasks && unsubscribeTasks();
      unsubscribeShifts && unsubscribeShifts();
    };
  });
};

const getCurrentWeekRange = () => {
  const now = new Date();
  const sunday = new Date(now);
  sunday.setDate(now.getDate() - now.getDay());
  sunday.setHours(0, 0, 0, 0);

  const saturday = new Date(now);
  saturday.setDate(now.getDate() + (6 - now.getDay()));
  saturday.setHours(23, 59, 59, 999);

  return [sunday, saturday];
};

function VinCollect({ userRole }) {
  const [dateRange, setDateRange] = useState(getCurrentWeekRange());
  const [startDate, endDate] = dateRange;
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [expanded, setExpanded] = useState({});
  const [sortDirection, setSortDirection] = useState("desc");
  const [clientVehicleIds, setClientVehicleIds] = useState("");
  const [showComparison, setShowComparison] = useState(false);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const [vinCollectUsers, setVinCollectUsers] = useState({});

  const {
    data,  // This will now contain { tasks, vinCollectUsers }
    isLoading,
    error,
  } = useQuery({
    queryKey: ["tasks", startDate, endDate],
    queryFn: () => fetchTasks(startDate, endDate),
    enabled: !!startDate && !!endDate,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  // Wrap tasks initialization in useMemo
  const tasks = useMemo(() => data?.tasks || [], [data?.tasks]);

  // Update the existing useEffect
  useEffect(() => {
    if (data?.vinCollectUsers) {
      setVinCollectUsers(data.vinCollectUsers);
    }
  }, [data?.vinCollectUsers]);

  // Update the locations useMemo to use memoized tasks
  const locations = useMemo(() => {
    if (!tasks) return [];
    const uniqueLocations = [
      ...new Set(tasks.map((task) => task.locationName)),
    ];
    return uniqueLocations.sort();
  }, [tasks]);

  // Update the filteredTasks useMemo to use memoized tasks
  const filteredTasks = useMemo(() => {
    if (!tasks) return [];

    return tasks.filter((task) => {
      // First filter by location if needed
      if (
        selectedLocation !== "all" &&
        task.locationName !== selectedLocation
      ) {
        return false;
      }

      // Then check if the task was created during a "Vin Collect" period
      const shift = task.shift;
      if (!shift?.roleChanges?.length) {
        return false; // No role changes defined, skip task
      }

      const taskTime = task.startTimestamp?.toDate();
      if (!taskTime) {
        return false; // No start time, skip task
      }

      // Sort role changes by timestamp
      const roleChanges = [...shift.roleChanges].sort(
        (a, b) => a.timestamp.seconds - b.timestamp.seconds
      );

      // Find the role at the time of task creation
      const roleAtTaskTime = roleChanges.reduce((currentRole, change) => {
        const changeTime = change.timestamp.toDate();
        return changeTime <= taskTime ? change.role : currentRole;
      }, roleChanges[0].role);

      // Only include tasks created when role was "Vin Collect"
      return roleAtTaskTime === "Vin Collect";
    });
  }, [tasks, selectedLocation]);

  const groupedTasks = useMemo(() => {
    if (!filteredTasks) return [];

    // Create a Set of all valid user IDs (from both tasks and vinCollectUsers)
    const allVinCollectUsers = new Set([
      ...filteredTasks.map((task) => task.userId),
      ...(vinCollectUsers ? Object.keys(vinCollectUsers) : [])
    ]);

    const groupedByUser = Array.from(allVinCollectUsers).reduce((acc, userId) => {
      const userTasks = filteredTasks.filter((task) => task.userId === userId);
      const userShift = vinCollectUsers?.[userId];

      // Only add the user if they have tasks OR they are a valid Vin Collect user
      if (userShift?.user || userTasks[0]?.user) {
        acc[userId] = {
          userId,
          user: userShift?.user || userTasks[0]?.user,
          workedHours: userTasks.reduce(
            (sum, task) => sum + (task.workedHours || 0),
            0
          ),
          taskCount: userTasks.length,
          revenue: userTasks.reduce((sum, task) => sum + (task.revenue || 0), 0),
          payroll: userTasks.reduce((sum, task) => sum + (task.payroll || 0), 0),
          grossProfit: userTasks.reduce(
            (sum, task) => sum + (task.grossProfit || 0),
            0
          ),
        };
      }

      return acc;
    }, {});

    return Object.values(groupedByUser).map((group) => ({
      ...group,
      tasksPerHour: group.workedHours > 0 ? group.taskCount / group.workedHours : 0,
      grossProfitPercentage: group.revenue > 0 ? (group.grossProfit / group.revenue) * 100 : 0,
    }));
  }, [filteredTasks, vinCollectUsers]);

  const vehicleComparison = useMemo(() => {
    if (!clientVehicleIds || !filteredTasks) return null;

    // Convert client vehicle IDs into a Set (trimming whitespace and removing empty lines)
    const clientIds = new Set(
      clientVehicleIds
        .split(/[\n,]/) // Split by newline or comma
        .map((id) => id.trim())
        .filter((id) => id.length > 0)
    );

    // Get our vehicle IDs from tasks
    const ourIds = new Set(
      filteredTasks.map((task) => task.vehicleId).filter((id) => id) // Remove null/undefined
    );

    // Calculate the differences
    const missingFromUs = [...clientIds].filter((id) => !ourIds.has(id));
    const missingFromClient = [...ourIds].filter((id) => !clientIds.has(id));
    const matchingIds = [...clientIds].filter((id) => ourIds.has(id));

    return {
      missingFromUs,
      missingFromClient,
      matchingIds,
      totalClientIds: clientIds.size,
      totalOurIds: ourIds.size,
    };
  }, [clientVehicleIds, filteredTasks]);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        header: () => null,
        cell: ({ row }) => (
          <button
            {...{
              onClick: row.getToggleExpandedHandler(),
              className: "px-2",
            }}
          >
            <FontAwesomeIcon
              icon={row.getIsExpanded() ? faChevronDown : faChevronRight}
              className="text-gray-500"
            />
          </button>
        ),
      },
      {
        header: "Employee",
        accessorFn: (row) => {
          if (!row.user) return "Unknown User";
          return `${row.user.firstName} ${row.user.lastName}`;
        },
      },
      {
        header: "Tasks",
        accessorKey: "taskCount",
      },
      {
        header: "Worked Hours",
        accessorFn: (row) => {
          const hours = row.workedHours || 0;
          return Number(hours.toFixed(2));
        },
      },
      {
        header: "Tasks/Hour",
        accessorKey: "tasksPerHour",
        cell: (info) => `${Number(info.getValue()).toFixed(2)} tasks/hr`,
      },
      ...(userRole !== "supervisor" && userRole !== "manager"
        ? [
            {
              header: "Revenue/Hour",
              accessorFn: (row) => row.revenue / row.workedHours || 0,
              cell: (info) => `$${Number(info.getValue()).toFixed(2)}/hr`,
            },
            {
              header: "Profit/Hour",
              accessorFn: (row) => row.grossProfit / row.workedHours || 0,
              cell: (info) => `$${Number(info.getValue()).toFixed(2)}/hr`,
            },
            {
              header: "Revenue",
              accessorFn: (row) => row.revenue || 0,
              cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
            },
            {
              header: "Payroll",
              accessorFn: (row) => row.payroll || 0,
              cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
            },
            {
              header: "Gross Profit",
              accessorFn: (row) => row.grossProfit || 0,
              cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
            },
          ]
        : []),
      {
        header: "Gross Profit %",
        accessorFn: (row) => row.grossProfitPercentage || 0,
        cell: (info) => `${Number(info.getValue()).toFixed(1)}%`,
      },
    ],
    [userRole]
  );

  const renderSubRow = useCallback(
    (row) => {
      const userTasks = filteredTasks
        .filter((task) => task.userId === row.original.userId)
        .sort((a, b) => {
          const aTime = a.startTimestamp?.seconds || 0;
          const bTime = b.startTimestamp?.seconds || 0;
          return sortDirection === "desc" ? bTime - aTime : aTime - bTime;
        });

      return (
        <tr>
          <td colSpan={columns.length} className="bg-gray-50 p-4">
            <div className="flex justify-start mb-4">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setSortDirection((prev) =>
                    prev === "desc" ? "asc" : "desc"
                  );
                }}
                className="inline-flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-lg 
                          text-sm font-medium text-gray-700 hover:bg-gray-50 hover:border-gray-400 
                          transition-all duration-200 shadow-sm"
              >
                <svg
                  className={`w-4 h-4 transition-transform duration-200 ${
                    sortDirection === "desc" ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 7l4-4m0 0l4 4m-4-4v18"
                  />
                </svg>
                {sortDirection === "desc" ? "Newest First" : "Oldest First"}
              </button>
            </div>
            <div className="grid grid-cols-1 gap-4">
              {userTasks.map((task) => (
                <div key={task.id} className="bg-white p-4 rounded-lg shadow">
                  <div className="grid grid-cols-4 gap-4">
                    {/* Time Information */}
                    <div>
                      <p className="text-sm font-semibold text-gray-600">
                        Start Time
                      </p>
                      <p>
                        {task.startTimestamp?.seconds
                          ? new Date(
                              task.startTimestamp.seconds * 1000
                            ).toLocaleString()
                          : "Not started"}
                      </p>
                      <p className="text-sm font-semibold text-gray-600 mt-2">
                        End Time
                      </p>
                      <p>
                        {task.endTimestamp?.seconds
                          ? new Date(
                              task.endTimestamp.seconds * 1000
                            ).toLocaleString()
                          : "In progress"}
                      </p>
                    </div>
                    {/* Task Details */}
                    <div>
                      <p className="text-sm font-semibold text-gray-600">
                        Task Name
                      </p>
                      <p>{task.taskName || "N/A"}</p>
                      <p className="text-sm font-semibold text-gray-600 mt-2">
                        Vehicle ID
                      </p>
                      <p>{task.vehicleId || "N/A"}</p>
                    </div>
                    {/* Location and Price */}
                    <div>
                      <p className="text-sm font-semibold text-gray-600">
                        Location
                      </p>
                      <p>{task.locationName || "N/A"}</p>
                      {userRole !== "supervisor" && userRole !== "manager" && (
                        <>
                          <p className="text-sm font-semibold text-gray-600 mt-2">
                            Price
                          </p>
                          <p>${task.price || "0.00"}</p>
                        </>
                      )}
                    </div>
                    {/* Metrics */}
                    <div>
                      <p className="text-sm font-semibold text-gray-600">
                        Duration
                      </p>
                      <p>
                        {task.workedHours
                          ? `${Math.floor(task.workedHours)}h ${Math.round(
                              (task.workedHours % 1) * 60
                            )}m`
                          : "0h 0m"}
                      </p>
                      {userRole !== "supervisor" && userRole !== "manager" && (
                        <>
                          <p className="text-sm font-semibold text-gray-600 mt-2">
                            Gross Profit
                          </p>
                          <p>
                            ${(task.grossProfit || 0).toFixed(2)} (
                            {(task.grossProfitPercentage || 0).toFixed(1)}%)
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </td>
        </tr>
      );
    },
    [filteredTasks, columns, sortDirection, userRole]
  );

  const table = useReactTable({
    data: groupedTasks,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
  });

  const summaryMetrics = useMemo(() => {
    if (!groupedTasks?.length) return null;

    return {
      taskCount: groupedTasks.reduce((sum, group) => sum + group.taskCount, 0),
      workedHours: Number(
        groupedTasks.reduce((sum, group) => sum + (group.workedHours || 0), 0).toFixed(2)
      ),
      revenue: Number(
        groupedTasks.reduce((sum, group) => sum + (group.revenue || 0), 0).toFixed(2)
      ),
      payroll: groupedTasks.reduce((sum, group) => sum + (group.payroll || 0), 0),
      grossProfit: groupedTasks.reduce((sum, group) => sum + (group.grossProfit || 0), 0),
    };
  }, [groupedTasks]);

  const renderSummarySection = () => {
    if (!summaryMetrics) return null;

    const formatMetric = (value, suffix = "") => {
      const num = Number(value.toFixed(2));
      return `${num.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}${suffix}`;
    };

    const tasksPerHour = summaryMetrics.workedHours
      ? summaryMetrics.taskCount / summaryMetrics.workedHours
      : 0;
    const grossProfitPercentage = summaryMetrics.revenue
      ? (summaryMetrics.grossProfit / summaryMetrics.revenue) * 100
      : 0;

    return (
      <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-lg font-semibold text-white mb-4">
          {selectedLocation === "all"
            ? "Overall Summary"
            : `Summary for ${selectedLocation}`}
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div className="bg-gray-700 p-4 rounded-lg">
            <div className="text-gray-400 text-sm">Tasks</div>
            <div className="text-white text-2xl font-bold">
              {summaryMetrics.taskCount.toLocaleString()}
            </div>
            <div className="text-blue-400 text-sm mt-1">
              {tasksPerHour.toFixed(2)} tasks/hr
            </div>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg">
            <div className="text-gray-400 text-sm">Hours Worked</div>
            <div className="text-white text-2xl font-bold">
              {formatMetric(summaryMetrics.workedHours)}
            </div>
          </div>
          {userRole === "owner" && (
            <div className="bg-gray-700 p-4 rounded-lg">
              <div className="text-gray-400 text-sm">Revenue</div>
              <div className="text-white text-2xl font-bold">
                ${formatMetric(summaryMetrics.revenue)}
              </div>
            </div>
          )}
          <div className="bg-gray-700 p-4 rounded-lg">
            <div className="text-gray-400 text-sm">Gross Profit</div>
            <div className="text-white text-2xl font-bold">
              {userRole === "owner" ? (
                <>
                  ${formatMetric(summaryMetrics.grossProfit)}
                  <div className="text-blue-400 text-sm mt-1">
                    {grossProfitPercentage.toFixed(1)}% margin
                  </div>
                </>
              ) : (
                <div>{grossProfitPercentage.toFixed(1)}% margin</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderVehicleComparison = () => (
    <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-white">
          Vehicle ID Comparison
        </h2>
        <button
          onClick={() => setShowComparison(!showComparison)}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          {showComparison ? "Hide Comparison" : "Show Comparison"}
        </button>
      </div>

      {showComparison && (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Paste Client Vehicle IDs (one per line or comma-separated)
            </label>
            <textarea
              value={clientVehicleIds}
              onChange={(e) => setClientVehicleIds(e.target.value)}
              className="w-full h-32 p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter vehicle IDs..."
            />
          </div>

          {vehicleComparison && clientVehicleIds && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-gray-700 p-4 rounded-lg">
                <h3 className="text-white font-semibold mb-2">
                  Missing From Us
                </h3>
                <p className="text-gray-400 text-sm mb-2">
                  {vehicleComparison.missingFromUs.length} vehicles
                </p>
                <div className="max-h-40 overflow-y-auto">
                  {vehicleComparison.missingFromUs.map((id) => (
                    <div key={id} className="text-red-400 text-sm py-1">
                      {id}
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-gray-700 p-4 rounded-lg">
                <h3 className="text-white font-semibold mb-2">
                  Missing From Client
                </h3>
                <p className="text-gray-400 text-sm mb-2">
                  {vehicleComparison.missingFromClient.length} vehicles
                </p>
                <div className="max-h-40 overflow-y-auto">
                  {vehicleComparison.missingFromClient.map((id) => (
                    <div key={id} className="text-yellow-400 text-sm py-1">
                      {id}
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-gray-700 p-4 rounded-lg">
                <h3 className="text-white font-semibold mb-2">Matching IDs</h3>
                <p className="text-gray-400 text-sm mb-2">
                  {vehicleComparison.matchingIds.length} vehicles
                </p>
                <div className="max-h-40 overflow-y-auto">
                  {vehicleComparison.matchingIds.map((id) => (
                    <div key={id} className="text-green-400 text-sm py-1">
                      {id}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  const getFormattedFileName = () => {
    const location =
      selectedLocation === "all"
        ? "all-locations"
        : selectedLocation.replace(/\s+/g, "-").toLowerCase();
    const startDateFormatted = format(startDate, "yyyy-MM-dd");
    const endDateFormatted = format(endDate, "yyyy-MM-dd");
    const exportDateTime = format(new Date(), "yyyy-MM-dd_HH-mm-ss");

    return `tasks_${location}_${startDateFormatted}_${endDateFormatted}_${exportDateTime}.pdf`;
  };

  const taskSummary = useMemo(() => {
    if (!filteredTasks) return { totalTasks: 0, tasks: [] };

    const taskMap = filteredTasks.reduce((acc, task) => {
      if (!acc[task.taskName]) {
        acc[task.taskName] = {
          name: task.taskName,
          quantity: 0,
          price: task.price || 0,
        };
      }
      acc[task.taskName].quantity += 1;
      return acc;
    }, {});

    return {
      totalTasks: filteredTasks.length,
      tasks: Object.values(taskMap),
    };
  }, [filteredTasks]);

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  if (error)
    return (
      <div className="text-red-500 text-center">Error: {error.message}</div>
    );

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <div className="py-8">
        <div>
          <h1 className="text-lg font-bold text-white uppercase tracking-wider">
            Car Wash Tasks Report
          </h1>
        </div>

        {/* Filters Section with Export Button */}
        <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
          <div className="flex flex-row items-end justify-between">
            <div className="flex items-end space-x-4">
              {/* Date Range Picker */}
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold text-gray-300">
                  Date Range
                </label>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => setDateRange(update)}
                  className="w-72 p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                  placeholderText="Select date range"
                  dateFormat="MMM dd, yyyy"
                />
              </div>

              {/* Location Dropdown */}
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold text-gray-300">
                  Location
                </label>
                <div className="relative">
                  <select
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    className="w-48 appearance-none p-3 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 pr-10"
                  >
                    <option value="all">All Locations</option>
                    {locations.map((location) => (
                      <option key={location} value={location}>
                        {location}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            {/* PDF Export Button */}
            {userRole !== "supervisor" && userRole !== "manager" && (
              <div className="h-[42px]">
                <button
                  onClick={() => setIsPDFModalOpen(true)}
                  disabled={selectedLocation === "all"}
                  className={`h-full px-4 text-white rounded-lg transition-all duration-200 shadow-lg flex items-center gap-2
        ${
          selectedLocation === "all"
            ? "bg-gray-500 cursor-not-allowed opacity-50"
            : "bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 hover:shadow-xl"
        }`}
                >
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                  {selectedLocation === "all"
                    ? "Select a Location to Export"
                    : "Export to PDF"}
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Summary Section */}
        {renderSummarySection()}

        {/* Vehicle Comparison Section */}
        {renderVehicleComparison()}

        {/* Table section */}
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <React.Fragment key={row.id}>
                    <tr
                      key={row.id}
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={() => row.toggleExpanded()}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                    {row.getIsExpanded() && renderSubRow(row)}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* PDF Export Modal */}
        {isPDFModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-gray-800 rounded-lg p-6 max-w-lg w-full mx-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-white">
                  Export to PDF
                </h3>
                <button
                  onClick={() => setIsPDFModalOpen(false)}
                  className="text-gray-400 hover:text-white"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="mb-4">
                <p className="text-gray-300 mb-2">Preparing PDF export for:</p>
                <ul className="text-gray-400 list-disc list-inside space-y-1">
                  <li>
                    Location:{" "}
                    {selectedLocation === "all"
                      ? "All Locations"
                      : selectedLocation}
                  </li>
                  <li>
                    Date Range: {format(startDate, "MMM dd, yyyy")} -{" "}
                    {format(endDate, "MMM dd, yyyy")}
                  </li>
                  <li>Total Tasks: {taskSummary.totalTasks}</li>
                </ul>
              </div>

              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setIsPDFModalOpen(false)}
                  className="px-4 py-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors"
                >
                  Cancel
                </button>

                <PDFDownloadLink
                  document={
                    <VinCollectPDF
                      taskSummary={taskSummary}
                      filteredTasks={filteredTasks}
                      selectedLocation={selectedLocation}
                      dateRange={[startDate, endDate]}
                    />
                  }
                  fileName={getFormattedFileName()}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors flex items-center gap-2"
                >
                  {({ blob, url, loading, error }) => (
                    <>
                      {loading ? (
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                      )}
                      {loading ? "Preparing PDF..." : "Download PDF"}
                    </>
                  )}
                </PDFDownloadLink>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VinCollect;
