import React, { useState, useMemo } from "react";
import { db } from "./firebase";
import {
  collection,
  query,
  getDocs,
  where,
  updateDoc,
  doc,
  arrayUnion,
  or,
  and,
} from "firebase/firestore";
import LoadingSpinner from "./LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faEdit,
  faSave,
  faPlus,
  faTrash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import { parseISO, format, differenceInDays, getYear } from "date-fns";
import "./Modal.css";

// Set the app element for react-modal
Modal.setAppElement("#root");

const TimeOff = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [currentField, setCurrentField] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingActions, setLoadingActions] = useState({});
  const [addingDateRange, setAddingDateRange] = useState({
    employeeId: null,
    field: null,
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const fetchEmployees = async () => {
    const usersRef = collection(db, "users");
    const q = query(
      usersRef,
      and(
        where("status", "==", "Active"),
        or(
          where("payMethod", "in", ["W2", "EIN"]),
          where("role", "in", [
            "supervisor",
            "manager",
            "admin",
            "owner",
            "director",
          ])
        )
      )
    );

    const querySnapshot = await getDocs(q);

    const employeeData = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      employeeData.push({
        id: doc.id,
        firstName: data.firstName || "Unknown",
        lastName: data.lastName || "Unknown",
        location: data.workLocation || "Unknown",
        hiringDate: data.hiringDate || "Unknown",
        vacationDays: (data.vacationDays || []).map(sanitizeDateRange),
        sickDays: (data.sickDays || []).map(sanitizeDateRange),
      });
    });

    return employeeData.sort((a, b) => a.firstName.localeCompare(b.firstName));
  };

  // Add this helper function
  const sanitizeDateRange = (range) => {
    const start = parseDate(range.start);
    const end = parseDate(range.end);
    return { start, end };
  };

  // Add this helper function
  const parseDate = (dateValue) => {
    if (dateValue instanceof Date) return dateValue;
    if (typeof dateValue === "string") {
      // Try parsing as ISO string
      const parsedDate = parseISO(dateValue);
      if (!isNaN(parsedDate.getTime())) return parsedDate;
    }
    if (dateValue && dateValue.seconds) {
      // Handle Firestore Timestamp
      return new Date(dateValue.seconds * 1000);
    }
    // If all else fails, return current date
    console.warn("Invalid date value, using current date instead:", dateValue);
    return new Date();
  };

  // Add this helper function to count days for a specific year
  const countDaysForYear = (dateRanges, year) => {
    return dateRanges.reduce((total, range) => {
      const start = parseDate(range.start);
      const end = parseDate(range.end);
      const startYear = getYear(start);
      const endYear = getYear(end);

      if (startYear === year && endYear === year) {
        return total + differenceInDays(end, start) + 1;
      } else if (startYear === year) {
        const yearEnd = new Date(year, 11, 31);
        return total + differenceInDays(yearEnd, start) + 1;
      } else if (endYear === year) {
        const yearStart = new Date(year, 0, 1);
        return total + differenceInDays(end, yearStart) + 1;
      } else if (startYear < year && endYear > year) {
        return total + 365; // or 366 for leap years, you might want to check for leap years
      }
      return total;
    }, 0);
  };

  const {
    data: employees,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["employees"],
    queryFn: fetchEmployees,
  });

  const filteredEmployees = useMemo(() => {
    return (
      employees?.filter(
        (employee) =>
          employee.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          employee.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          employee.location.toLowerCase().includes(searchTerm.toLowerCase())
      ) || []
    );
  }, [employees, searchTerm]);

  const updateEmployeeDays = useMutation({
    mutationFn: async ({ employeeId, field, value, isAdd, loadingKey }) => {
      const employeeRef = doc(db, "users", employeeId);
      if (isAdd) {
        await updateDoc(employeeRef, { [field]: arrayUnion(value) });
      } else {
        const employee = employees.find((e) => e.id === employeeId);
        const updatedDays = employee[field].filter(
          (day) => day.start !== value.start || day.end !== value.end
        );
        await updateDoc(employeeRef, { [field]: updatedDays });
      }
    },
    onMutate: async (variables) => {
      setLoadingActions((prev) => ({ ...prev, [variables.loadingKey]: true }));
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(["employees"]);
      setLoadingActions((prev) => ({ ...prev, [variables.loadingKey]: false }));
      setAddingDateRange({ employeeId: null, field: null });
    },
    onError: (_, variables) => {
      setLoadingActions((prev) => ({ ...prev, [variables.loadingKey]: false }));
      setAddingDateRange({ employeeId: null, field: null });
    },
  });

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsHeader = [
      [
        "First Name",
        "Last Name",
        "Work Location",
        "Hiring Date",
        "Vacation Days",
        "Sick Days",
      ],
    ];

    const wsData = employees.map((employee) => [
      employee.firstName,
      employee.lastName,
      employee.location,
      employee.hiringDate,
      employee.vacationDays,
      employee.sickDays,
    ]);

    const ws = XLSX.utils.aoa_to_sheet(wsHeader.concat(wsData));
    XLSX.utils.book_append_sheet(wb, ws, "Employee Time Off");

    const filename = `employee_time_off_${
      new Date().toISOString().split("T")[0]
    }.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  const handleAddDays = (employeeId, field) => {
    setCurrentEmployee(employeeId);
    setCurrentField(field);
    setDateRange([null, null]);
    setModalIsOpen(true);
  };

  const handleSaveDateRange = () => {
    if (startDate) {
      const newTimeOff = {
        start: startDate,
        end: endDate || startDate, // If endDate is null, use startDate
      };
      const loadingKey = `${currentEmployee}-${currentField}-add`;
      setAddingDateRange({ employeeId: currentEmployee, field: currentField });
      updateEmployeeDays.mutate({
        employeeId: currentEmployee,
        field: currentField,
        value: newTimeOff,
        isAdd: true,
        loadingKey,
      });
      setModalIsOpen(false);
    }
  };

  const handleRemoveDays = (employeeId, field, timeOff) => {
    const loadingKey = `${employeeId}-${field}-${timeOff.start}-${timeOff.end}-remove`;
    updateEmployeeDays.mutate({
      employeeId,
      field,
      value: sanitizeDateRange(timeOff),
      isAdd: false,
      loadingKey,
    });
  };

  const formatDate = (date) => {
    return date ? format(parseDate(date), "MM/dd/yyyy") : "";
  };

  const sortDateRanges = (dateRanges) => {
    return dateRanges.sort((a, b) => {
      const startA = parseDate(a.start);
      const startB = parseDate(b.start);
      return startA.getTime() - startB.getTime();
    });
  };

  if (error) {
    return (
      <div className="bg-[#1F2937] min-h-screen text-white p-8">
        Error: {error.message}
      </div>
    );
  }

  return (
    <div className="bg-[#1F2937] min-h-screen text-white p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Employee Time Off</h1>
        <div className="flex space-x-4">
          <button
            onClick={() => setIsEditMode(!isEditMode)}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FontAwesomeIcon
              icon={isEditMode ? faSave : faEdit}
              className="mr-2"
            />
            {isEditMode ? "Save" : "Edit"}
          </button>
          <button
            onClick={exportToExcel}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FontAwesomeIcon icon={faFileExport} className="mr-2" /> Export
          </button>
        </div>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Search employees..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 bg-gray-700 border border-gray-600 rounded text-white mr-4"
        />
        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          className="p-2 bg-gray-700 border border-gray-600 rounded text-white"
        >
          {Array.from(
            { length: 5 },
            (_, i) => new Date().getFullYear() - i
          ).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      {isLoading ? (
        <LoadingSpinner />
      ) : filteredEmployees.length === 0 ? (
        <p>No employees found matching your search.</p>
      ) : (
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="px-4 py-2 rounded-tl-lg">First Name</th>
              <th className="px-4 py-2">Last Name</th>
              <th className="px-4 py-2">Location</th>
              <th className="px-4 py-2">Hiring Date</th>
              <th className="px-4 py-2">Vacation Days</th>
              <th className="px-4 py-2">Sick Days</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {filteredEmployees.map((employee) => (
              <tr key={employee.id}>
                <td className="border px-4 py-2">{employee.firstName}</td>
                <td className="border px-4 py-2">{employee.lastName}</td>
                <td className="border px-4 py-2">{employee.location}</td>
                <td className="border px-4 py-2">{employee.hiringDate}</td>
                <td className="border px-4 py-2">
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-bold">
                      {countDaysForYear(employee.vacationDays, selectedYear)}{" "}
                      days in {selectedYear}
                    </span>
                    {isEditMode && (
                      <button
                        onClick={() =>
                          handleAddDays(employee.id, "vacationDays")
                        }
                        className="text-green-500 text-sm flex items-center"
                        disabled={
                          addingDateRange.employeeId === employee.id &&
                          addingDateRange.field === "vacationDays"
                        }
                      >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        Add Vacation
                      </button>
                    )}
                  </div>
                  {employee.vacationDays.length > 0 ? (
                    <div className="space-y-1">
                      {sortDateRanges(employee.vacationDays).map(
                        (vacation, index) => (
                          <div
                            key={index}
                            className="flex justify-between items-center text-sm"
                          >
                            <span>
                              {formatDate(vacation.start)} -{" "}
                              {formatDate(vacation.end)}
                            </span>
                            {isEditMode && (
                              <button
                                onClick={() =>
                                  handleRemoveDays(
                                    employee.id,
                                    "vacationDays",
                                    vacation
                                  )
                                }
                                className="text-red-500 ml-2"
                                disabled={
                                  loadingActions[
                                    `${employee.id}-vacationDays-${vacation.start}-${vacation.end}-remove`
                                  ]
                                }
                              >
                                {loadingActions[
                                  `${employee.id}-vacationDays-${vacation.start}-${vacation.end}-remove`
                                ] ? (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                ) : (
                                  <FontAwesomeIcon icon={faTrash} />
                                )}
                              </button>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <p className="text-gray-500 italic">
                      No vacation days recorded
                    </p>
                  )}
                  {addingDateRange.employeeId === employee.id &&
                    addingDateRange.field === "vacationDays" && (
                      <div className="text-blue-500 mt-2 text-sm flex items-center">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          className="mr-2"
                        />
                        Adding vacation days...
                      </div>
                    )}
                </td>
                <td className="border px-4 py-2">
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-bold">
                      {countDaysForYear(employee.sickDays, selectedYear)} days
                      in {selectedYear}
                    </span>
                    {isEditMode && (
                      <button
                        onClick={() => handleAddDays(employee.id, "sickDays")}
                        className="text-green-500 text-sm flex items-center"
                        disabled={
                          addingDateRange.employeeId === employee.id &&
                          addingDateRange.field === "sickDays"
                        }
                      >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        Add Sick Day
                      </button>
                    )}
                  </div>
                  {employee.sickDays.length > 0 ? (
                    <div className="space-y-1">
                      {sortDateRanges(employee.sickDays).map(
                        (sickDay, index) => (
                          <div
                            key={index}
                            className="flex justify-between items-center text-sm"
                          >
                            <span>
                              {formatDate(sickDay.start)} -{" "}
                              {formatDate(sickDay.end)}
                            </span>
                            {isEditMode && (
                              <button
                                onClick={() =>
                                  handleRemoveDays(
                                    employee.id,
                                    "sickDays",
                                    sickDay
                                  )
                                }
                                className="text-red-500 ml-2"
                                disabled={
                                  loadingActions[
                                    `${employee.id}-sickDays-${sickDay.start}-${sickDay.end}-remove`
                                  ]
                                }
                              >
                                {loadingActions[
                                  `${employee.id}-sickDays-${sickDay.start}-${sickDay.end}-remove`
                                ] ? (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                ) : (
                                  <FontAwesomeIcon icon={faTrash} />
                                )}
                              </button>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <p className="text-gray-500 italic">
                      No sick days recorded
                    </p>
                  )}
                  {addingDateRange.employeeId === employee.id &&
                    addingDateRange.field === "sickDays" && (
                      <div className="text-blue-500 mt-2 text-sm flex items-center">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          className="mr-2"
                        />
                        Adding sick days...
                      </div>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Add Time Off"
        className="modal"
        overlayClassName="overlay"
      >
        <h2 className="text-2xl font-bold mb-6 text-center">Add Time Off</h2>
        <div className="mb-6">
          <label className="block text-sm font-medium mb-2">
            Select Date Range
          </label>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            isClearable={true}
            className="w-full p-2 bg-gray-700 border border-gray-600 rounded text-white"
            calendarClassName="bg-gray-800"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => setModalIsOpen(false)}
            className="px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white font-medium rounded transition duration-150 ease-in-out"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveDateRange}
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded transition duration-150 ease-in-out flex items-center"
            disabled={
              !startDate ||
              loadingActions[`${currentEmployee}-${currentField}-add`]
            }
          >
            {loadingActions[`${currentEmployee}-${currentField}-add`] ? (
              <>
                <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                Saving...
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default TimeOff;