import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";

const UniquePunch = () => {
  const openAppLink = () => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const iOSLink = "https://apps.apple.com/us/app/unique-punch/id6463495209";
    const androidLink =
      "https://play.google.com/store/apps/details?id=com.rickyalves.uniquePunch";

    if (isIOS) {
      window.open(iOSLink, "_blank");
    } else if (/android/i.test(navigator.userAgent)) {
      window.open(androidLink, "_blank");
    } else {
      window.open(iOSLink, "_blank");
      window.open(androidLink, "_blank");
    }
  };

  const iOSLink = "https://apps.apple.com/us/app/unique-punch/id6463495209";
  const appId = "6463495209";

  useEffect(() => {
    const iOSMetaTag = document.createElement('meta');
    iOSMetaTag.name = "apple-itunes-app";
    iOSMetaTag.content = `app-id=${appId}, app-argument=${iOSLink}`;
    document.head.prepend(iOSMetaTag);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <meta
          name="apple-itunes-app"
          content={`app-id=${appId}, app-argument=${iOSLink}`}
        />
      </Helmet>
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-600 via-purple-500 to-pink-500 p-4">
        <div className="flex flex-col items-center justify-center text-center p-6 bg-white/30 backdrop-blur-md border border-white/10 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 max-w-md w-full">
          <div className="text-white text-5xl md:text-6xl audiowide mb-4">
            UNIQUE
          </div>
          <div className="text-white text-5xl md:text-6xl audiowide">PUNCH</div>
          <p className="text-base md:text-lg mb-6 md:mb-10 text-gray-100">
            Effortlessly manage your time and attendance.
          </p>
          <button
            onClick={openAppLink}
            className="flex items-center justify-center gap-2 px-6 py-3 md:px-8 md:py-4 bg-gradient-to-r from-blue-500 to-green-400 text-white font-semibold rounded-full shadow-lg hover:shadow-2xl transition duration-300 transform hover:-translate-y-1 hover:scale-110"
            aria-label="Download or Update App"
          >
            <FontAwesomeIcon icon={faApple} className="text-lg md:text-xl" />
            <FontAwesomeIcon icon={faAndroid} className="text-lg md:text-xl" />
            Download the App
          </button>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default UniquePunch;
