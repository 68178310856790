import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";

export const GoogleMapsContext = React.createContext({
  isLoaded: false,
  loadError: null,
});

const libraries = ["places", "maps"];

export function GoogleMapsProvider({ children }) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
}
