import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../assets/images/unique.png";

const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: "#ffffff",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
    borderBottom: 2,
    borderBottomColor: "#2563eb", // blue-600
    paddingBottom: 15,
  },
  logo: {
    width: 128,
    height: 66,
  },
  title: {
    fontSize: 28,
    color: "#1e3a8a", // blue-900
    fontWeight: "bold",
    flex: 1,
    marginRight: 20,
  },
  section: {
    marginBottom: 25,
  },
  sectionTitle: {
    fontSize: 20,
    color: "#1e3a8a", // blue-900
    fontWeight: "bold",
    marginBottom: 15,
    borderBottom: 1,
    borderBottomColor: "#e5e7eb", // gray-200
    paddingBottom: 5,
  },
  infoText: {
    fontSize: 12,
    color: "#4b5563", // gray-600
    marginBottom: 5,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#e5e7eb", // gray-200
    borderBottomStyle: "solid",
    paddingVertical: 8,
  },
  tableHeader: {
    backgroundColor: "#f3f4f6", // gray-100
    fontWeight: "bold",
  },
  tableCell: {
    flex: 1,
    padding: 8,
    fontSize: 11,
    color: "#374151", // gray-700
  },
  taskDetails: {
    marginLeft: 20,
    padding: 12,
    backgroundColor: "#f9fafb", // gray-50
    marginBottom: 12,
    borderRadius: 4,
    borderLeftWidth: 3,
    borderLeftColor: "#2563eb", // blue-600
    borderLeftStyle: "solid",
  },
  totalRow: {
    backgroundColor: "#f3f4f6", // gray-100
  },
  boldText: {
    fontWeight: "bold",
  },
  summaryText: {
    fontSize: 14,
    color: "#1f2937", // gray-800
    marginBottom: 8,
  },
});

const VinCollectPDF = ({
  taskSummary,
  filteredTasks,
  selectedLocation,
  dateRange,
}) => {
  const [startDate, endDate] = dateRange;

  // Calculate total revenue
  const totalRevenue = taskSummary.tasks.reduce(
    (sum, task) => sum + task.price * task.quantity,
    0
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>Car Wash Tasks Report</Text>
          <Image style={styles.logo} src={logo} />
        </View>

        {/* Report Info Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Report Information</Text>
          <Text style={styles.infoText}>
            Date Range: {startDate.toLocaleDateString()} -{" "}
            {endDate.toLocaleDateString()}
          </Text>
          <Text style={styles.infoText}>
            Location:{" "}
            {selectedLocation === "all" ? "All Locations" : selectedLocation}
          </Text>
          <Text style={styles.summaryText}>
            Total Tasks Completed: {taskSummary.totalTasks}
          </Text>
        </View>

        {/* Task Summary Table */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Tasks Summary</Text>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={styles.tableCell}>Task Name</Text>
              <Text style={styles.tableCell}>Quantity</Text>
              <Text style={styles.tableCell}>Price per Task</Text>
              <Text style={styles.tableCell}>Total</Text>
            </View>
            {taskSummary.tasks.map((task) => (
              <View key={task.name} style={styles.tableRow}>
                <Text style={styles.tableCell}>{task.name}</Text>
                <Text style={styles.tableCell}>{task.quantity}</Text>
                <Text style={styles.tableCell}>${task.price.toFixed(2)}</Text>
                <Text style={styles.tableCell}>
                  ${(task.quantity * task.price).toFixed(2)}
                </Text>
              </View>
            ))}
            <View style={[styles.tableRow, styles.totalRow]}>
              <Text style={[styles.tableCell, styles.boldText]}>Total</Text>
              <Text style={styles.tableCell}>{taskSummary.totalTasks}</Text>
              <Text style={styles.tableCell}></Text>
              <Text style={[styles.tableCell, styles.boldText]}>
                ${totalRevenue.toFixed(2)}
              </Text>
            </View>
          </View>
        </View>

        {/* Detailed Tasks */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Task Details</Text>
          {filteredTasks.map((task) => (
            <View key={task.id} style={styles.taskDetails}>
              <Text style={styles.infoText}>
                Date: {task.startTimestamp.toDate().toLocaleDateString()}
              </Text>
              <Text style={styles.infoText}>
                Employee: {task.user ? `${task.user.firstName} ${task.user.lastName}` : 'Unknown'}
              </Text>
              <Text style={styles.infoText}>Task: {task.taskName}</Text>
              <Text style={styles.infoText}>
                Vehicle ID: {task.vehicleId || "N/A"}
              </Text>
              <Text style={styles.infoText}>Location: {task.locationName}</Text>
              <Text style={styles.infoText}>
                Price: ${task.price?.toFixed(2) || "0.00"}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default VinCollectPDF;
